import { ITerritory } from './territories';

export interface ICustomer {
  accountName: string;
  accountRole: string;
  createdAt: number;
  email: string;
  firstName: string;
  id: number;
  inactive: boolean;
  isBroker: boolean;
  lastName: string;
  showOms: boolean;
  territory: ITerritory | null;
  updatedAt: number;
  omsAcct: string;
}

export type ICustomers = Array<ICustomer>;

export interface IUpdateCustomerPayload {
  id: number;
  isBroker?: boolean;
  showOms?: boolean;
  territoryId?: number;
  inactive?: boolean;
  omsAcct?: string;
}

export interface IGetCustomerPayload {
  showUsers: SHOW_USERS;
}

export enum SHOW_USERS {
  CUSTOMER,
  ADMIN,
  INACTIVE
}

export interface IShowUsersControlOption {
  name: string;
  value: SHOW_USERS;
}

export type IShowUsersControlOptions = IShowUsersControlOption[];

export const SHOW_USERS_RADIO_BUTTONS: IShowUsersControlOptions = [{
  name: 'Customers',
  value: SHOW_USERS.CUSTOMER
}, {
  name: 'Admins',
  value: SHOW_USERS.ADMIN
}, {
  name: 'Hidden Users',
  value: SHOW_USERS.INACTIVE
}];

export interface IEmail {
  email: string;
}
