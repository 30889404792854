import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

import { routerAnimation } from '../../../../utils/page.animation';

@Component({
  selector: 'bl-cms-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  animations: [routerAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageNotFoundComponent {
  // Add router animation
  @HostBinding('@routerAnimation') routerAnimation = true;
}
