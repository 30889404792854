import { AuthEffects } from '../../../auth/store/effects/auth.effect';

import { AvailableTerritoriesEffect } from './available-territories.effect';
import { ProfileEffect } from './profile.effect';
import { RouterEffect } from './router.effect';

export const effects = [
  AuthEffects,
  ProfileEffect,
  RouterEffect,
  AvailableTerritoriesEffect
];
