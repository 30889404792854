import { Inject, Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { defer, of, throwError, Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { getAdminProfileAction } from '../../../core/store/actions/profile.actions';
import { State } from '../../../core/store/reducers/core.reducers';
import * as authActions from '../actions';

import { ProfileService } from '../../../core/services/profile.service';

import { AuthService, TokenProvider } from '../../providers';
import { ONE_LOGIN_URL } from '../../providers/one-login-url.provider';

import { IToken } from '../../../../models';

@Injectable()
export class AuthEffects {

  constructor(private _actions$: Actions,
              private _authService: AuthService,
              private _profileService: ProfileService,
              private _store: Store<State>,
              @Inject(ONE_LOGIN_URL) private _oneLoginUrl: string) {
  }

  logOut$: Observable<unknown> = createEffect(() => defer(() => this._actions$
    .pipe(
      ofType(authActions.unAuthorizeAction),
      tap(() => {
        TokenProvider.resetToken();
        window.location.href = this._oneLoginUrl;
      }),
      catchError(err => throwError(err))
    )), { dispatch: false });

  getAuthToken$: Observable<unknown> = createEffect(() => defer(() => this._actions$
    .pipe(
      ofType(authActions.getAuthTokenAction),
      switchMap(({ payload: { code, callback } }) => this._authService
        .getToken(code)
        .pipe(
          tap(({ token }: IToken) => {
            TokenProvider.token = token;
            setTimeout(() => {
              this._store.dispatch(authActions.getAuthTokenSuccessAction({ token, callback }));
            }, 200);
          }),
          catchError((err) => of(authActions.getAuthTokenErrorAction(err)))
        )),
      catchError(err => throwError(err))
    )), { dispatch: false });

  getProfileOnTokenSuccess$: Observable<Action> = createEffect(() => this._actions$
    .pipe(
      ofType(authActions.getAuthTokenSuccessAction),
      map(({ payload: { callback } }) => getAdminProfileAction({ callback })),
      catchError(err => throwError(err))
    ));

}
