import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { defer, of, throwError, Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import * as profileActions from '../actions/profile.actions';
import { Go } from '../actions/router.action';
import { State } from '../reducers/profile.reducer';

import { ProfileService } from '../../services/profile.service';

import { IAdminResponse, ITokenResponse } from '../../../../models';

@Injectable()
export class ProfileEffect {

  constructor(private actions: Actions,
              private store: Store<State>,
              private profileService: ProfileService) {
  }

  getProfile: Observable<Action> = createEffect(() => this.actions
    .pipe(
      ofType(profileActions.getAdminProfileAction),
      map(({ payload }) => payload),
      switchMap(({ callback, withoutRedirect }) => this.profileService
        .getProfile()
        .pipe(
          map((admin: IAdminResponse) => profileActions.getAdminProfileSuccessAction({
            admin,
            callback,
            withoutRedirect
          })),
          catchError((error: any) => of(profileActions.getAdminProfileErrorAction({ error: error.error })))
        )),
      catchError(error => throwError(error))
    ));

  redirectAfterGettingProfile: Observable<unknown> = createEffect(() => defer(() => this.actions
    .pipe(
      ofType(profileActions.getAdminProfileSuccessAction),
      tap(({ payload: { callback, withoutRedirect } }) => {

        if (!withoutRedirect) {
          this.store.dispatch(Go(['/main']));
        }

        if (callback) {
          callback();
        }

      }),
      catchError(error => throwError(error))
    )), { dispatch: false });

  updateProfile: Observable<Action> = createEffect(() => this.actions
    .pipe(
      ofType(profileActions.updateAdminProfileAction),
      map((action: any) => action.payload),
      switchMap((payload: IAdminResponse) => this.profileService.updateProfile(payload)
        .pipe(
          map((admin: IAdminResponse) => profileActions.updateAdminProfileSuccessAction(admin)),
          catchError((error: any) => of(profileActions.getAdminProfileErrorAction({ error: error.error })))
        )
      ),
      catchError(error => throwError(error))
    ));

  loginAsMyself = createEffect(() => this.actions
    .pipe(
      ofType(profileActions.loginAsMyselfAction),
      switchMap(() => this.profileService.loginAsMyself()
        .pipe(
          tap(({ token }: ITokenResponse) => ProfileService.loginToCustomerApp(token)),
          map((data: ITokenResponse) => profileActions.loginAsMyselfSuccessAction(data)),
          catchError((error: any) => of(profileActions.loginAsMyselfErrorAction({ error: error.error })))
        )
      ),
      catchError(error => throwError(error))
    ));
}
