import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { CustomRouterStateSerializer } from './store/custom-router-state-serializer';
import { effects } from './store/effects';
import { reducers } from './store/reducers/core.reducers';

import { AuthModule } from '../auth/auth.module';
import { ResizeModule } from '../resize/resize.module';
import { ThemeModule } from '../theme/theme.module';
import { CoreRoutesModule } from './core-routes.module';

import { MainPageComponent } from './pages/main-page/main-page.component';
import { PageErrorComponent } from './pages/page-error/page-error.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

import { AvailableTerritoriesService } from './services/available-territories.service';
import { ProfileService } from './services/profile.service';

import { environment } from '../../../environments/environment';
import { AppErrorInterceptor } from './errors/app-error-interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';


@NgModule({
  imports:      [
    AuthModule,
    ThemeModule,
    CommonModule,

    HttpClientModule,

    ResizeModule,
    OverlayModule,

    PortalModule,
    BrowserAnimationsModule,

    CoreRoutesModule,

    StoreRouterConnectingModule.forRoot({ stateKey: '[ROUTER]:' }),
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      }
    }),
    EffectsModule.forRoot(effects),
    environment.production ? [] : StoreDevtoolsModule.instrument(),
  ],
  providers:    [
    ProfileService,
    AvailableTerritoriesService,
    {
      provide:  HTTP_INTERCEPTORS,
      useClass: AppErrorInterceptor,
      multi:    true
    },
    {
      provide:  HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi:    true
    },
    {
      provide:  RouterStateSerializer,
      useClass: CustomRouterStateSerializer
    },
  ],
  exports: [
    CoreRoutesModule
  ],
  declarations: [
    PageNotFoundComponent,
    PageErrorComponent,
    MainPageComponent,
  ]
})
export class CoreModule {
}
