import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AuthInitProvider, AuthService, TokenProvider } from './providers';
import { OneLoginUrlProvider } from './providers/one-login-url.provider';

import { AuthGuard } from '../core/guards/auth.guard';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    AuthGuard,
    AuthService,
    TokenProvider,
    OneLoginUrlProvider,
    AuthInitProvider,
  ]
})
export class AuthModule {
}
