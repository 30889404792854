import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, Actions, Effect } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { throwError ,  Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import * as routerActions from '../actions/router.action';

@Injectable()
export class RouterEffect {

  @Effect({ dispatch: false })
  navigate$: Observable<Action> = this.actions$
    .pipe(
      ofType(routerActions.goAction),
      tap((action) => {
        const { payload: { url, queryParams } } = action;
        this.router.navigate(url, { queryParams });
      }),
      catchError(error => throwError(error))
    );

  constructor(private actions$: Actions, private router: Router) {
  }
}
