import { createAction, Action } from '@ngrx/store';

import { createPayload } from '../../../../utils/type';

enum ActionTypes {
  GO = '[Router] Go',
}

export interface IGoActionPayload {
  url: string[];
  queryParams?: { [ket: string]: string | number | boolean };
}

export const goAction = createAction(ActionTypes.GO, createPayload<IGoActionPayload>());

export function Go(url: string[], queryParams?: { [ket: string]: string | number | boolean }): Action {
  return goAction({ url, queryParams });
}
