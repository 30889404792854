import { createReducer, on, Action } from '@ngrx/store';

import * as actions from '../actions/profile.actions';

import { AdminProfile, IPermissions } from '../../../../models';

export interface State {
  profile: AdminProfile | null;
  loading: boolean;
  error: string;
  loginAsMyselfLoading: boolean;
}

const initialState: State = {
  profile: null,
  loading: false,
  error: null,
  loginAsMyselfLoading: false,
};

const getAdminProfile = (state: State): State => ({
  ...state,
  loading: true,
  error: null
});

const getAdminProfileError = (state: State, { payload }): State => ({
  ...state,
  loading: false,
  error: payload.error.message,
});

const getAdminProfileSuccess = (state: State, { payload }): State => ({
  ...state,
  profile: new AdminProfile(payload.admin),
  loading: false,
});

const updateAdminProfile = (state: State): State => ({
  ...state,
  error: null,
  loading: true
});

const updateAdminProfileError = (state: State, { payload }): State => ({
  ...state,
  loading: false,
  error: payload.error.message
});

const updateAdminProfileSuccess = (state: State, { payload }): State => ({
  ...state,
  loading: false,
  profile: new AdminProfile(payload)
});

const loginAsMyself = (state: State): State => ({
  ...state,
  loginAsMyselfLoading: true,
});

const loginAsMyselfError = (state: State): State => ({
  ...state,
  loginAsMyselfLoading: false,
});

const loginAsMyselfSuccess = (state: State): State => ({
  ...state,
  loginAsMyselfLoading: false,
});

const profileReducer = createReducer<State>(
  initialState,

  on(actions.getAdminProfileAction, getAdminProfile),
  on(actions.getAdminProfileErrorAction, getAdminProfileError),
  on(actions.getAdminProfileSuccessAction, getAdminProfileSuccess),

  on(actions.updateAdminProfileAction, updateAdminProfile),
  on(actions.updateAdminProfileErrorAction, updateAdminProfileError),
  on(actions.updateAdminProfileSuccessAction, updateAdminProfileSuccess),

  on(actions.loginAsMyselfAction, loginAsMyself),
  on(actions.loginAsMyselfErrorAction, loginAsMyselfError),
  on(actions.loginAsMyselfSuccessAction, loginAsMyselfSuccess)
);

export function reducer(state: State,
                        action: Action): State {
  return profileReducer(state, action);
}

export const getProfile = (state: State): AdminProfile | null => state.profile;
export const isAuthorized = (profile: AdminProfile | null): boolean => profile instanceof AdminProfile;
export const getPermissions = (profile: AdminProfile): IPermissions => profile.role.permissions;
export const getProfileErrors = (state: State): string => state.error;
export const isLoading = (state: State): boolean => state.loading;

export const loginAsMyselfLoading = (state: State): boolean => state.loginAsMyselfLoading;
