import { APP_INITIALIZER, Provider } from '@angular/core';
import { Store } from '@ngrx/store';

import { getAdminProfileAction } from '../../core/store/actions/profile.actions';
import { Go } from '../../core/store/actions/router.action';
import { State } from '../../core/store/reducers/core.reducers';
import * as authActions from '../store/actions';

import { AuthService } from './auth.service';

import { getQueryStaticParameters } from '../../../utils/functional-utils';

import { TokenProvider } from './token.provider';

export function init(store: Store<State>): () => Promise<any> {
  const queryParams = getQueryStaticParameters();

  return () => {
    return new Promise((resolve) => {

      if (queryParams.hasOwnProperty('code')) {
        TokenProvider.resetToken();

        store.dispatch(authActions.getAuthTokenAction(
          {
            code: queryParams['code'],
            callback: () => resolve()
          })
        );

      } else if (!!TokenProvider.token) {

        store.dispatch(getAdminProfileAction({
          callback: () => resolve(),
          withoutRedirect: !queryParams.hasOwnProperty('code')
        }));

      } else if (queryParams.hasOwnProperty('error') && queryParams.hasOwnProperty('error_description')) {

        const _queryParams = {
          ...queryParams,
          error_description: decodeURI(queryParams.error_description)
        };
        store.dispatch(Go(['/error'], _queryParams));
        resolve();

      } else {
        store.dispatch(authActions.unAuthorizeAction());
      }
    });
  };
}

export const AuthInitProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: init,
  deps: [Store, AuthService],
  multi: true
};
