import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bl-cms-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageErrorComponent {
  errorMessage: string = this._aRouter.snapshot.queryParams['error_description'];

  constructor(private _aRouter: ActivatedRoute) { }
}
