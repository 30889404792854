// core
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { MainPageComponent } from './pages/main-page/main-page.component';
import { PageErrorComponent } from './pages/page-error/page-error.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

import { AuthGuard } from './guards/auth.guard';
import { AvailableTerritoriesExistGuard } from './guards/available-territories-exist.guard';
import { SuperAdminGuard } from './guards/super-admin.guard';

// guards

// pages

// routes
const APP_ROUTES: Routes = [{
  path: '',
  pathMatch: 'full',
  redirectTo: 'main'
}, {
  path: 'auth',
  redirectTo: 'main',
  pathMatch: 'full'
}, {
  path: 'main',
  component: MainPageComponent,
  canActivate: [AuthGuard],
  children: [{
    path: '',
    redirectTo: 'customers',
    pathMatch: 'prefix'
  }, {
    path: 'customers',
    loadChildren: () => import('./../customers/customers.module')
      .then(m => m.CustomersModule),
    canActivate: [AvailableTerritoriesExistGuard]
  }, {
    path: 'user',
    loadChildren: () => import('../edit-profile/edit-profile.module')
      .then(m => m.EditProfileModule)
  }, {
    path: 'administrators',
    loadChildren: () => import('./../administrators/administrators.module')
      .then(m => m.AdministratorsModule),
    canActivate: [SuperAdminGuard, AvailableTerritoriesExistGuard],
  }]
}, {
  path: 'error',
  component: PageErrorComponent
}, {
  path: '**',
  component: PageNotFoundComponent
}];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, { preloadingStrategy: PreloadAllModules }),
  ],
  providers: [
    AuthGuard,
    SuperAdminGuard,
    AvailableTerritoriesExistGuard
  ],
  exports: [
    RouterModule
  ]
})
export class CoreRoutesModule {
}
