import { createReducer, on, Action } from '@ngrx/store';

import * as actions from '../actions/available-teritoties.actions';

import { ITerritories } from '../../../../models';

export interface State {
  territories: ITerritories | null;
  pending: boolean;
  error: any;
}

export const initialState: State = {
  territories: null,
  pending: false,
  error: null
};

const getAvailableTerritories = (state: State): State => ({
  ...state,
  pending: true,
  error: null
});

const getAvailableTerritoriesError = (state: State, { payload }): State => ({
  ...state,
  pending: false,
  error: payload
});

const getAvailableTerritoriesSuccess = (state: State, { payload }): State => ({
  ...state,
  pending: false,
  territories: payload
});

const availableTerritoriesReducer = createReducer<State>(
  initialState,

  on(actions.getAvailableTerritoriesAction, getAvailableTerritories),
  on(actions.getAvailableTerritoriesErrorAction, getAvailableTerritoriesError),
  on(actions.getAvailableTerritoriesSuccessAction, getAvailableTerritoriesSuccess)
);

export function reducer(state: State,
                        action: Action): State {
  return availableTerritoriesReducer(state, action);
}

export const territories = (state: State): ITerritories => state.territories;
export const pending = (state: State): boolean => state.pending;
