import { Injectable } from '@angular/core';

const TOKEN_KEY = 'token';

@Injectable()
export class TokenProvider {
  static get token(): string | null {
    return localStorage.getItem(TOKEN_KEY) || null;
  }

  static set token(token: string) {
    localStorage.setItem(TOKEN_KEY, token);
  }

  static resetToken(): void {
    localStorage.removeItem(TOKEN_KEY);
  }
}
