import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

import { SideitemComponent } from './item/sideitem.component';
import { SidemenuComponent } from './menu/sidemenu.component';

@NgModule({
  imports:      [
    CommonModule,
    RouterModule,
    MatButtonModule
  ],
  declarations: [SidemenuComponent, SideitemComponent],
  exports:      [SidemenuComponent, SideitemComponent]
})
export class SidemenuModule {
}
