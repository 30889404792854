import { createAction } from '@ngrx/store';

import { createPayload } from '../../../../utils/type';

import { IToken } from '../../../../models';

enum ActionTypes {
  GET_AUTH_TOKEN = '[AUTH] Get token',
  GET_AUTH_TOKEN_ERROR = '[AUTH] Get token error',
  GET_AUTH_TOKEN_SUCCESS = '[AUTH] Get token success',

  UNAUTHORIZED = '[AUTH] UNAUTHORIZED'
}

export const getAuthTokenAction = createAction(ActionTypes.GET_AUTH_TOKEN, createPayload<{ code: string, callback: () => void }>());
export const getAuthTokenErrorAction = createAction(ActionTypes.GET_AUTH_TOKEN_ERROR, createPayload<{ error: string }>());
export const getAuthTokenSuccessAction = createAction(ActionTypes.GET_AUTH_TOKEN_SUCCESS, createPayload<IToken>());

export const unAuthorizeAction = createAction(ActionTypes.UNAUTHORIZED);
