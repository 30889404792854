import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { pluckAndCatch } from '../../../utils/formatter';

import { ITerritories } from '../../../models';

import { environment } from '../../../../environments/environment';

@Injectable()
export class AvailableTerritoriesService {
  private readonly _baseUrl = `${ environment.api.profile.base }`;
  private readonly _url = `${ environment.api.profile.territories }`;

  constructor(private _http: HttpClient) {
  }

  getAvailable(): Observable<ITerritories | Error> {
    return this._http.get(`${ this._baseUrl }${ this._url }`)
      .pipe(pluckAndCatch);
  }
}
