export enum CUSTOMER_ROLES {
  OWNER = 'account-owner',
  ADMIN = 'account-admin',
  USER = 'account-user'
}

export const CUSTOMER_ROLES_NAME = Object.freeze({
  [CUSTOMER_ROLES.OWNER]: 'Owner',
  [CUSTOMER_ROLES.ADMIN]: 'Admin',
  [CUSTOMER_ROLES.USER]: 'User'
});
