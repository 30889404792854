<mat-sidenav-container>
  <mat-sidenav #sidenav [mode]="sidenavMode"
               [opened]="sideNavOpened" class="left-sidenav"
               (opened)="resizeService.resizeInformer$.next()"
               (close)="resizeService.resizeInformer$.next()">
    <header class="sidenav-header" fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>arrow_back</mat-icon>
      </button>

      <div fxFlex class="text-center pad-right-xl">
        <a (click)="sidenav.toggle()" [routerLink]="['/main', 'customers', 'list']">
          <!--<img *ngIf="darkTheme; else darkLogo" src="/assets/logo/logo-text-white.png">-->
          <ng-template #darkLogo>
            <!--<img src="/assets/logo/logo-text-black.png">-->
          </ng-template>
        </a>
      </div>
    </header>

    <!--<perfect-scrollbar class="perfect-scrollbar">-->
      <bl-cms-px-gdn-sidemenu>
        <bl-cms-px-gdn-sideitem title="My Account"
                                (click)="sidenav.toggle()"
                                [routerLinkUrl]="'/main/user/profile'">
          <mat-icon>account_box</mat-icon>
        </bl-cms-px-gdn-sideitem>

        <!-- todo change logic if we will have access to route by groups and permissions -->
        <bl-cms-px-gdn-sideitem title="Administrators"
                                *ngIf="(adminProfileRole$ | async)?.id === adminRoles.Super"
                                (click)="sidenav.toggle()"
                                [routerLinkUrl]="'/main/administrators/list'">
          <mat-icon>supervisor_account</mat-icon>
        </bl-cms-px-gdn-sideitem>

        <bl-cms-px-gdn-sideitem title="Users list"
                                (click)="sidenav.toggle()"
                                [routerLinkUrl]="'/main/customers/list'">
          <mat-icon>format_align_left</mat-icon>
        </bl-cms-px-gdn-sideitem>

        <!--<bl-cms-px-gdn-sideitem title="Login by email"-->
                                <!--(click)="sidenav.toggle()"-->
                                <!--[routerLinkUrl]="'/main/customers/email-login'">-->
          <!--<mat-icon>contact_mail</mat-icon>-->
        <!--</bl-cms-px-gdn-sideitem>-->

      </bl-cms-px-gdn-sidemenu>
    <!--</perfect-scrollbar>-->
  </mat-sidenav>

  <mat-sidenav #optionsnav mode="over" position="end">
    <mat-toolbar color="primary">
      <h5>Theme options</h5>
      <span fxFlex></span>
      <button mat-icon-button (click)="optionsnav.close()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>

    <div class="pad" fxLayoutAlign="space-between center">
      <span class="mat-subhead">Dark theme</span>
      <mat-slide-toggle [(ngModel)]="darkTheme"></mat-slide-toggle>
    </div>

    <div class="pad" fxLayoutAlign="space-between center">
      <span fxFlex class="mat-subhead">Sidenav mode</span>
      <mat-select fxFlex [(ngModel)]="sidenavMode">
        <mat-option value="side">Side</mat-option>
        <mat-option value="over">Over</mat-option>
        <mat-option value="push">Push</mat-option>
      </mat-select>
    </div>

    <div class="pad" fxLayoutAlign="space-between center">
      <span class="mat-subhead">Boxed layout</span>
      <mat-slide-toggle [(ngModel)]="boxedLayout"></mat-slide-toggle>
    </div>
  </mat-sidenav>

  <mat-toolbar color="primary">

    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>

    <a [routerLink]="['/main', 'customers', 'list']" class="logo">
      <img *ngIf="darkTheme; else darkLogo" src="/assets/logo/logo-text-white.png">
      <!-- use logo.svg when it will be fixed -->
      <ng-template #darkLogo>
        <img src="/assets/logo/logo-text-black.png">
      </ng-template>
    </a>

    <span fxFlex></span>

    <button mat-icon-button [matMenuTriggerFor]="settingsMenu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #settingsMenu="matMenu">
      <button mat-menu-item (click)="goToProfile()">
          <mat-icon fontSet="fontawesome" fontIcon="fa-sign-out"></mat-icon>
          My Account
      </button>

      <button mat-menu-item title="Options" (click)="optionsnav.toggle()">
        <mat-icon>settings</mat-icon>
        Options
      </button>

      <button mat-menu-item (click)="logout()">
        <mat-icon fontSet="fontawesome" fontIcon="fa-sign-out"></mat-icon>
        Sign-Out
      </button>
    </mat-menu>
  </mat-toolbar>

  <div class="wrapper" [class.wrapper-box]="boxedLayout">
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-container>
