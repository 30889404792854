import { Params } from '@angular/router';


export const getQueryStaticParameters = (): Params => {
  const queryString = location.search.slice(1),
    params = {};

  queryString.replace(/([^=]*)=([^&]*)&*/g, (_, key, value) => {
    return params[key] = value;
  });

  return params;
};
