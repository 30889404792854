import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { State } from '../store/reducers/core.reducers';
import { getIsAuthorized } from '../store/selectors/profile.selector';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private _store: Store<State>) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> {
    return this._store
      .select(getIsAuthorized)
      .pipe(
        filter((isAuth: boolean) => isAuth),
        take(1)
      );
  }
}
