import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {  Observable } from 'rxjs';

import { pluckAndCatch } from '../../../utils/formatter';

import { IAdminResponse, ITokenResponse } from '../../../models';

import { environment } from '../../../../environments/environment';

@Injectable()
export class ProfileService {

  constructor(private http: HttpClient) {
  }

  static loginToCustomerApp(token: string): void {
    window.location.href = `${environment.customerAppUrl}/${environment.asMyself}?token=${token}`;
  }

  getProfile(): Observable<IAdminResponse | Error> {
    return this.http.get(`${environment.api.profile.get}`)
      .pipe(pluckAndCatch);
  }

  updateProfile(updateData: IAdminResponse): Observable<IAdminResponse | Error> {
    return this.http.patch(`${environment.api.profile.update}`, updateData)
      .pipe(pluckAndCatch);
  }

  loginAsMyself(): Observable<ITokenResponse | Error> {
    return this.http.post(`${environment.api.auth.loginAsMyself}`, null)
      .pipe(pluckAndCatch);
  }

}
