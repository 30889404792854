import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { pluckAndCatch } from '../../../utils/formatter';

import { IToken } from '../../../models';

import { environment } from '../../../../environments/environment';

@Injectable()
export class AuthService {

  constructor(private http: HttpClient) {
  }

  getToken(token: string): Observable<IToken> {
    return this.http.post(`${ environment.api.auth.login }`, { token })
      .pipe(pluckAndCatch);
  }
}
