import { createSelector } from '@ngrx/store';

import * as profile from '../reducers/profile.reducer';

import { getProfileState } from './index';

export const getLoginAsMyselfLoading = createSelector(getProfileState, profile.loginAsMyselfLoading);
export const getAdminProfile = createSelector(getProfileState, profile.getProfile);
export const getAdminProfileError = createSelector(getProfileState, profile.getProfileErrors);
export const getIsAdminProfileLoading = createSelector(getProfileState, profile.isLoading);

export const getPermissions = createSelector(getAdminProfile, profile.getPermissions);
export const getIsAuthorized = createSelector(getAdminProfile, profile.isAuthorized);
