import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector:    'bl-cms-app-root',
  templateUrl: './app.component.html',
  styleUrls:   ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {

  @HostBinding('class.loading') loading = true;

  constructor(mdIconRegistry: MatIconRegistry) {
    mdIconRegistry.registerFontClassAlias('fontawesome', 'fa');

    this.loading = false;
  }
}
