import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { unAuthorizeAction } from '../../auth/store/actions';
import { Go } from '../store/actions/router.action';

@Injectable()
export class AppErrorInterceptor implements HttpInterceptor {

  constructor(private injector: Injector,
              private snackBar: MatSnackBar) {
  }

  store = this.injector.get(Store);


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          switch (error.status) {
            case 401:
              this.store.dispatch(unAuthorizeAction());
              break;
            case 403: {
              this.snackBar.open(error.error.message, 'X', {
                duration: 3000,
                horizontalPosition: 'end',
                verticalPosition: 'top'
              });
              break;
            }
            case 404:
              this.store.dispatch(Go(['/404']));
              break;
          }

          return throwError(error);
        })
      );
  }
}
