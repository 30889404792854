// core
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

// app core
import { CoreModule } from './modules/core/core.module';

import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports:      [
    BrowserModule,
    CoreModule
  ],
  providers:    [],
  bootstrap:    [AppComponent]
})
export class AppModule {
}
