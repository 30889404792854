import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  QueryList
} from '@angular/core';

import { Subscription } from 'rxjs';

import { CloseService } from '../close.service';

@Component({
  selector:    'bl-cms-px-gdn-sideitem',
  templateUrl: './sideitem.component.html',
  styleUrls:   ['./sideitem.component.scss'],
  animations:  [
    trigger('closeState', [
      state('close', style({
        height: '0'
      })),
      state('open', style({
        height: '*'
      })),
      transition('close => open', animate('250ms ease-in')),
      transition('open => close', animate('250ms ease-out'))
    ])],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideitemComponent implements OnDestroy, AfterContentInit {

  private closeServiceSubscription: Subscription;
  @Input() title: string;
  @Input() closeState = 'close';
  @Input() routerLinkUrl: string;
  menuLevel = -1;

  @ContentChildren(SideitemComponent, { descendants: true }) contentChildren: QueryList<SideitemComponent>;

  constructor(private closeServiceService: CloseService, public location: Location) {
    this.closeServiceSubscription = closeServiceService.closeInformer$.subscribe(next => {
      if (next === this.menuLevel) {
        this.closeState = 'close';
      }
    });
  }

  ngAfterContentInit(): void {
    this.contentChildren.forEach((item) => {
      item.incrementMenuLEvel();
    });
  }

  ngOnDestroy(): void {
    this.closeServiceSubscription.unsubscribe();
  }

  toggleState(hasChildren: boolean): void {
    if (hasChildren) {
      if (this.closeState === 'close') {
        this.closeServiceService.inform(this.menuLevel);
      }
      this.closeState = this.closeState === 'close' ? 'open' : 'close';
    }
  }

  incrementMenuLEvel(): void {
    this.menuLevel++;
  }
}
