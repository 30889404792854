import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import { ADMINISTRATORS_STATE_NAME, IAdministratorsState } from '../../../administrators/store/reducers';
import { CUSTOMERS_STATE_NAME, ICustomersState } from '../../../customers/store/reducers';

import {
  reducer as availableTerritoriesReducer,
  State as AvailableTerritoriesState
} from './available-teritories.reducer';
import {
  reducer as profileReducer,
  State as ProfileState,
} from './profile.reducer';

export interface State {
  router: RouterReducerState;
  availableTerritories: AvailableTerritoriesState;
  profile: ProfileState;

  [CUSTOMERS_STATE_NAME]?: ICustomersState;
  [ADMINISTRATORS_STATE_NAME]?: IAdministratorsState;
}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer,
  availableTerritories: availableTerritoriesReducer,
  profile: profileReducer
};
