export const environment = {
  production:     true,
  envName:        'qa',
  customerAppUrl: 'https://qa.build-a-list-test.com/auth',
  asAdmin: 'login-as-admin',
  asMyself: 'login-as-myself',
  keys: {
    oneLogin: {
      scope: 'openid',
      response_type: 'code',
      authUrl: 'https://dnbstg.onelogin.com/oidc/2/auth',
      client_id: 'ea02afa0-956c-0139-40f9-0a35553cc5ef72164',
      redirect_uri: 'https://cms.qa.build-a-list-test.com/auth/one-login',
      logout: 'https://dnbqa.onelogin.com/logout'
    }
  },
  api:            {
    auth:      {
      login:        'https://s5qp4t4bt1.execute-api.us-east-1.amazonaws.com/qa/api/v1/admin/login',
      loginAsUser:  'https://s5qp4t4bt1.execute-api.us-east-1.amazonaws.com/qa/api/v1/admin/login-as-user',
      refreshToken: 'https://s5qp4t4bt1.execute-api.us-east-1.amazonaws.com/qa/api/v1/admin/refresh-token',
      loginAsMyself: 'https://s5qp4t4bt1.execute-api.us-east-1.amazonaws.com/qa/api/v1/admin/login-as-myself',
      forgot: 'https://s5qp4t4bt1.execute-api.us-east-1.amazonaws.com/qa/api/v1/admin/password/reset',
      reset: 'https://s5qp4t4bt1.execute-api.us-east-1.amazonaws.com/qa/api/v1/admin/password/verify-reset'
    },
    profile:   {
      base: 'https://s5qp4t4bt1.execute-api.us-east-1.amazonaws.com/qa/api/v1/admin/',
      territories: 'territories',
      get:    'https://s5qp4t4bt1.execute-api.us-east-1.amazonaws.com/qa/api/v1/admin/profile',
      update: 'https://s5qp4t4bt1.execute-api.us-east-1.amazonaws.com/qa/api/v1/admin/profile'
    },
    customers: {
      get:      'https://s5qp4t4bt1.execute-api.us-east-1.amazonaws.com/qa/api/v1/admin/customers',
      getToken: 'https://s5qp4t4bt1.execute-api.us-east-1.amazonaws.com/qa/api/v1/admin/login-as-user',
      checkCustomerRegisterStatus: 'https://s5qp4t4bt1.execute-api.us-east-1.amazonaws.com/qa/api/v1/admin/customers/check',
      update: 'https://s5qp4t4bt1.execute-api.us-east-1.amazonaws.com/qa/api/v1/admin/customers'
    },
    administrators: {
      main: 'https://s5qp4t4bt1.execute-api.us-east-1.amazonaws.com/qa/api/v1/admin/administrators',
    }
  }
};
