import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { of, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as actions from '../actions/available-teritoties.actions';

import { AvailableTerritoriesService } from '../../services/available-territories.service';

import { errorHandler } from '../../../../utils/formatter';

import { ITerritories } from '../../../../models';

@Injectable()
export class AvailableTerritoriesEffect {

  constructor(private actions$: Actions,
              private service: AvailableTerritoriesService) {
  }

  get$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(actions.getAvailableTerritoriesAction),
      switchMap(() => this.service.getAvailable()
        .pipe(
          map((territories: ITerritories) => actions.getAvailableTerritoriesSuccessAction(territories)),
          catchError((error: any) => of(actions.getAvailableTerritoriesErrorAction(error)))
        )),
      catchError(errorHandler)
    ));
}
