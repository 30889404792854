import { ChangeDetectionStrategy, Component } from '@angular/core';

import { CloseService } from '../close.service';

@Component({
  selector:    'bl-cms-px-gdn-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls:   ['./sidemenu.component.scss'],
  providers:   [CloseService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidemenuComponent {
}
