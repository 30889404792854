import { InjectionToken, Provider } from '@angular/core';

import { environment } from '../../../../environments/environment';

export const ONE_LOGIN_URL = new InjectionToken<string>('ONE_LOGIN_URL');

export const OneLoginUrlProvider: Provider = {
  provide: ONE_LOGIN_URL,
  useValue: `${
    environment.keys.oneLogin.authUrl }?client_id=${
    environment.keys.oneLogin.client_id }&response_type=${
    environment.keys.oneLogin.response_type }&scope=${
    environment.keys.oneLogin.scope }&redirect_uri=${
    environment.keys.oneLogin.redirect_uri
  }`,
};


