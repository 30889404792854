import { createAction } from '@ngrx/store';

import { createPayload } from '../../../../utils/type';

import { ITerritories } from '../../../../models';

enum ActionTypes {
  GET = '[AVAILABLE TERRITORIES] Get',
  GET_ERROR = '[AVAILABLE TERRITORIES] Get Error ',
  GET_SUCCESS = '[AVAILABLE TERRITORIES] Get Success'
}

export const getAvailableTerritoriesAction = createAction(ActionTypes.GET);
export const getAvailableTerritoriesErrorAction = createAction(ActionTypes.GET_ERROR, createPayload<{ error: string }>());
export const getAvailableTerritoriesSuccessAction = createAction(ActionTypes.GET_SUCCESS, createPayload<ITerritories>());
