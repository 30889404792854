import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TokenProvider } from '../../auth/providers';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  static applyCredentials(req: HttpRequest<any>): HttpRequest<any> {
    const token = TokenProvider.token;

    const headers = {
      'Authorization': token ? 'Bearer ' + token : ''
    };

    return req.clone({
      setHeaders: headers
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(AuthInterceptor.applyCredentials(req))
      .pipe(catchError((error: HttpEvent<any>) => throwError(error)));
  }
}
